import {Button, DataStatus, Separator} from 'platform/components';
import {Box, Heading, HStack, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil, isNotNil, last} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {
  useCreateCarVerticalReportMutation,
  useGetCarVerticalReportsQuery,
} from '@dms/api/carVertical';
import {useGetCarVerticalConfigQuery, useGetTenantQuery} from '@dms/api/tenant';
import {useGetUserQuery} from '@dms/api/user';
import i18n from '@dms/i18n';
import {DEFAULT_COUNTRY, getNaturalPersonFullName, handleApiError} from '@dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {CarVerticalLine} from './CarVerticalLine';
import {CarVerticalLogo} from './CarVerticalLogo';

export interface CarVerticalWidgetProps extends TestIdProps {
  vehicleId?: string;
}

export function CarVerticalWidget(props: CarVerticalWidgetProps) {
  const formatDateTime = useDateTimeFormatter();

  const {data: tenant} = useGetTenantQuery();
  const country = tenant?.country ?? DEFAULT_COUNTRY;

  const {data: carVerticalConfig} = useGetCarVerticalConfigQuery();

  const {
    data: reports,
    isLoading: isReportsLoading,
    isError: isReportsError,
  } = useGetCarVerticalReportsQuery(
    {vehicleId: props.vehicleId!},
    {
      skip: isNil(props.vehicleId) || !carVerticalConfig?.enabled,
    }
  );

  const lastReport = isNotNil(reports) ? last(reports) : null;

  const {data: user} = useGetUserQuery(
    {id: lastReport?.createdBy ?? ''},
    {skip: isNilOrEmpty(lastReport?.createdBy) || !carVerticalConfig?.enabled}
  );
  const userFullName = getNaturalPersonFullName(user);

  const [createCarVerticalReport, {isLoading: isCarVerticalReportCreating}] =
    useCreateCarVerticalReportMutation();

  const getNewReport = () => {
    if (carVerticalConfig?.enabled && isNotNil(props.vehicleId)) {
      createCarVerticalReport({
        vehicleId: props.vehicleId,
        createCarVerticalReportRequestBody: {country},
      })
        .unwrap()
        .then((report) => {
          window.open(report.url, '_blank');
        })
        .catch(handleApiError);
    }
  };

  if (!carVerticalConfig?.enabled) {
    return null;
  }

  return (
    <VStack spacing={2}>
      <Box backgroundColor="palettes.neutral.10.100" borderRadius="medium" overflow="hidden">
        <HStack>
          <CarVerticalLogo />
          <Box padding={4} width="100%" backgroundColor="palettes.neutral.10.100">
            <VStack spacing={3}>
              <Heading size={4}>{i18n.t('page.integrations.labels.carVerticalTitle')}</Heading>
              <HStack spacing={2}>
                <Show when={isNotNil(lastReport)}>
                  <Button
                    data-testid={suffixTestId('carVerticalWidget-viewReport', props)}
                    variant="secondary"
                    rightIcon="action/launch"
                    onClick={() => window.open(lastReport?.url ?? '')}
                    title={i18n.t('page.integrations.labels.carVerticalViewReport')}
                  />
                </Show>
                <Button
                  data-testid={suffixTestId('carVerticalWidget-newReport', props)}
                  isLoading={isCarVerticalReportCreating}
                  onClick={getNewReport}
                  variant="secondary"
                  title={i18n.t('page.integrations.labels.carVerticalNewReport')}
                />
              </HStack>
              <Show when={isNotNil(lastReport)}>
                <Text size="xSmall" color="tertiary">
                  {`${i18n.t('page.integrations.labels.carVerticalLastReport')} ${
                    lastReport?.createdAt
                      ? formatDateTime('dateShort', parseDate(lastReport.createdAt))
                      : ''
                  }`}
                  {isNotNilOrEmpty(userFullName) ? `, ${userFullName}` : ''}
                </Text>
              </Show>
              <Show when={isReportsLoading || isReportsError || isPositive(reports?.length)}>
                <Separator spacing={1} />
              </Show>
              <DataStatus
                isLoading={isReportsLoading}
                isError={isReportsError}
                errorMessage={i18n.t('page.integrations.errors.carVerticalReportsLoadError')}
              >
                <VStack spacing={2}>
                  {reports?.map((report) => <CarVerticalLine key={report.id} report={report} />)}
                </VStack>
              </DataStatus>
            </VStack>
          </Box>
        </HStack>
      </Box>
    </VStack>
  );
}
