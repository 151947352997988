import {DataStatus, Form, FormField} from 'platform/components';
import {Box} from 'platform/foundation';

import {getWorkspaceFromUri} from '@dms/api/core';
import {useGetAvailableLanguagesListQuery} from '@dms/api/public';
import {useSetUserSettingsItemMutation} from '@dms/api/user';
import {browserStorageKey} from '@dms/config';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {MyProfileFooter} from '../../../components/MyProfileFooter';
import {MyProfileModuleProps} from '../../../types/MyProfileModuleProps';
import {yupLanguageFormSchema} from './yupLanguageFormSchema';

type LanguageFormType = {
  language: string;
};

const {workspace} = getWorkspaceFromUri();

export function LanguageForm(props: MyProfileModuleProps) {
  const [updateUserSettings] = useSetUserSettingsItemMutation();
  const {data, isLoading, isError} = useGetAvailableLanguagesListQuery({
    workspace: workspace as string,
  });

  const handleSubmit = async ({language}: LanguageFormType) => {
    await updateUserSettings({
      settingKey: 'selected_language',
      userSettingRequestBody: {
        value: language,
      },
    })
      .unwrap()
      .then(props.persistor.purge)
      .then(() => i18n.changeLanguage(language))
      .then(() => {
        localStorage.setItem(browserStorageKey.LAST_KNOWN_LANGUAGE, language);
        window.location.reload();
      })
      .catch(handleApiError);
  };

  const languageOptions = Object.entries(data?.languages ?? {}).map(([value, label]) => ({
    label,
    value,
  }));

  const defaultValues = {
    language:
      i18n?.resolvedLanguage ||
      (localStorage[browserStorageKey.LAST_KNOWN_LANGUAGE] as string | undefined) ||
      data?.default,
  };

  return (
    <Box maxWidth={100}>
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<LanguageFormType>
          onSubmit={handleSubmit}
          shouldWatchForUnsavedChanges
          defaultValues={defaultValues}
          schema={yupLanguageFormSchema}
        >
          {(control, formApi) => (
            <>
              <FormField
                control={control}
                isRequired
                type="choice"
                isNotClearable
                name="language"
                options={languageOptions}
                data-testid={testIds.myProfile.language('language')}
              />

              <MyProfileFooter
                actions={[
                  {
                    type: 'button',
                    onClick: () => formApi.reset(defaultValues),
                    title: i18n.t('general.actions.discardChanges'),
                    variant: 'secondary',
                    'data-testid': testIds.myProfile.language('discard'),
                  },
                  {
                    type: 'form-button',
                    control,
                    buttonType: 'submit',
                    title: i18n.t('general.actions.saveChanges'),
                    'data-testid': testIds.myProfile.language('save'),
                  },
                ]}
              />
            </>
          )}
        </Form>
      </DataStatus>
    </Box>
  );
}
