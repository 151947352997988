import {Button, Flag, useIntersectionObserver} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';

import {useCallback, useState} from 'react';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps, useToggle} from 'shared';

import {HighlightItemType} from './types/HighlightItemType';

interface VehicleSnippetHighlightsWithMoreProps extends TestIdProps {
  data: HighlightItemType[];
}

export function VehicleHighlights(props: VehicleSnippetHighlightsWithMoreProps) {
  const [isExpanded, toggleExpanded] = useToggle(false);
  const [hiddenFeaturesCount, setHiddenFeaturesCount] = useState<number>(0);

  const handleIntersection = useCallback((entries: IntersectionObserverEntry[]) => {
    setHiddenFeaturesCount(entries.filter((entry) => !entry.isIntersecting).length);
  }, []);

  const featuresRef = useIntersectionObserver(handleIntersection);

  return (
    <Box maxHeight={isExpanded ? 'auto' : 13} overflow="hidden">
      <HStack spacing={1} wrap>
        {props.data.map((item, index) => (
          <div
            key={item.key}
            ref={(el) => {
              featuresRef.current[index] = el;
            }}
          >
            <HStack spacing={1}>
              <Flag
                label={item.label}
                isSubtle
                colorScheme="blue"
                data-testid={suffixTestId(`feature[${index}]`, props)}
              />
              <Show
                when={
                  !isExpanded &&
                  hiddenFeaturesCount > 0 &&
                  index === props.data.length - hiddenFeaturesCount - 1
                }
              >
                <Button
                  title={hiddenFeaturesCount + ' +'}
                  size="small"
                  onClick={toggleExpanded}
                  data-testid={suffixTestId('expandButton', props)}
                />
              </Show>
            </HStack>
          </div>
        ))}
        <Show when={isExpanded}>
          <Button
            title={i18n.t('general.actions.hide')}
            size="small"
            onClick={toggleExpanded}
            data-testid={suffixTestId('collapseButton', props)}
          />
        </Show>
      </HStack>
    </Box>
  );
}
