import {useSelector} from 'react-redux';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useLazyGetAutoFillTemplateQuery} from '@dms/api/carAudit';
import {handleApiError} from '@dms/shared';

import {useBoolean} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {updateAudit} from '../../../store/carAudit/actions';
import {AuditData, getAuditDataFromAutoFillTemplate} from '../../../store/carAudit/reducerUtils';
import {
  selectStructureCategoriesByUniqueKeys,
  selectStructureChildCategoriesByUniqueKeys,
} from '../../../store/carAudit/selectors';
import {AuditCategoryUniqueKey} from '../../../types/AuditCategoryUniqueKey';
import {getUpdateRequestBodyFromFormData} from '../../../utils/getUpdateRequestBodyFromFormData';
import {useConditionContext} from './useConditionContext';

// TODO: Replace with function from 'shared' for master. Prod does not jet has this function implementation in shared.
export function isNotNilOrEmpty<T>(value: T): value is NonNullable<T> {
  return isNilOrEmpty(value) === false;
}

const CATEGORIES_TO_EXCLUDE = [
  AuditCategoryUniqueKey.VEHICLE_IDENTIFICATION_NUMBER,
  AuditCategoryUniqueKey.YEAR_OF_PRODUCTION,
  AuditCategoryUniqueKey.CURRENT_MILEAGE,
];

const CHILD_CATEGORIES_OF_CATEGORIES_TO_EXCLUDE = [AuditCategoryUniqueKey.EQUIPMENT_VALIDATION];

export function useApplyAutoFillTemplate() {
  const dispatch = useThunkDispatch();
  const {loadActualData} = useConditionContext();
  const [getAutoFillTemplateData] = useLazyGetAutoFillTemplateQuery();
  const [isLoading, startLoading, stopLoading] = useBoolean(false);
  const categories = useSelector((state) =>
    selectStructureCategoriesByUniqueKeys(state, CATEGORIES_TO_EXCLUDE)
  );
  const childCategories = useSelector((state) =>
    selectStructureChildCategoriesByUniqueKeys(state, CHILD_CATEGORIES_OF_CATEGORIES_TO_EXCLUDE)
  );

  const categoriesKeysToNotOverride = [...categories, ...childCategories]
    .filter(isNotNilOrEmpty)
    .map((category) => category?.id);

  const applyAutoFillTemplate = async (templateId: string, audit: AuditData) => {
    startLoading();
    const autoFillTemplate = await getAutoFillTemplateData(
      {
        autoFillTemplateId: templateId,
        inspectionId: audit.id,
      },
      true
    )
      .unwrap()
      .catch(handleApiError);

    if (autoFillTemplate) {
      const mappedAuditFields = getAuditDataFromAutoFillTemplate(audit, autoFillTemplate, {
        overrideFields: true,
        doNotOverrideCategoriesKeys: categoriesKeysToNotOverride,
      });
      const updateAuditData = getUpdateRequestBodyFromFormData(mappedAuditFields.fields);

      await dispatch(
        updateAudit.action({
          auditId: audit.id,
          requestBody: updateAuditData,
        })
      )
        .unwrap()
        .catch(handleApiError);

      await loadActualData(audit.id).catch((error: {response: Error}) => {
        handleApiError(error.response);
      });
    }

    stopLoading();
  };

  return {applyAutoFillTemplate, isLoading};
}
