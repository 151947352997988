import {Box} from 'platform/foundation';

import {useRef} from 'react';

import {UserSidebarIconSize} from '@dms/api/shared';
import {NotificationsWidget, useNotifications} from '@dms/notifications';

import {TestIdProps} from 'shared';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {NotificationsButton} from './NotificationsButton';
import {TopBarItem, TopBarItemRef} from './TopBarItem';

interface NotificationsProps extends TestIdProps {
  iconSize: UserSidebarIconSize;
}

export function Notifications(props: NotificationsProps) {
  const ref = useRef<TopBarItemRef>(null);
  const isMobileNavigation = useIsMobileNavigation();
  const {
    data: notificationData,
    isLoading,
    isFetching,
    isError,
  } = useNotifications(NOTIFICATION_PAGE_SIZE);

  return (
    <TopBarItem
      gtmEventId="top_bar_notifications_open"
      ref={ref}
      isFullScreen={isMobileNavigation}
      trigger={(isOpen) => (
        <NotificationsButton
          isOpen={isOpen}
          iconSize={props.iconSize}
          data-testid={props['data-testid']}
        />
      )}
    >
      <Box
        padding={2}
        paddingTop={0}
        overflowY="auto"
        backgroundColor="general.white"
        minWidth={['auto', 'auto', 120]}
        maxHeight={['auto', 'auto', '80dvh']}
      >
        <NotificationsWidget
          notificationData={notificationData}
          isLoading={isLoading}
          isError={isError}
          isFetching={isFetching}
          pageSize={NOTIFICATION_PAGE_SIZE}
          onNotificationClick={() => {
            if (isMobileNavigation) {
              ref.current?.close();
            }
          }}
        />
      </Box>
    </TopBarItem>
  );
}

const NOTIFICATION_PAGE_SIZE = 20;
