import {ColorSchemeType, DataStatus, Segment} from 'platform/components';
import {Box, HStack, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';

import {always, isNotNil, omit} from 'ramda';

import {BusinessCaseType} from '@dms/api/commission';
import {GetCurrentUserInfoApiResponse} from '@dms/api/shared';
import {useGetCurrentUserInfoQuery, useGetUsersQuery} from '@dms/api/user';
import i18n from '@dms/i18n';
import {businessCaseRoutes, interestRoutes} from '@dms/routes';
import {EMPTY_PLACEHOLDER, getNaturalPersonFullName, Main} from '@dms/shared';

import {composePath, DOT_CHARACTER, isLastIndexInArray, pushGtmEvent, useOnMount} from 'shared';

import {cardColors} from '../constants/kanbanColors';
import {useKanbanControl} from '../hooks/useKanbanControl';
import {KanbanUser} from '../types/KanbanUser';
import {getCardsForColumn} from '../utils/getCardsForColumn';
import {getSelectedUserIds} from '../utils/getSelectedUserIds';
import {KanbanCard} from './KanbanCard';
import {KanbanUserControl} from './KanbanUserControl';

export function OpportunitiesKanban() {
  const {data: currentUserData} = useGetCurrentUserInfoQuery();

  const {data: users, isLoading: isUsersLoading, isError: isUsersErrored} = useGetUsersQuery();
  const {businessCaseType, businessCases, interests, isLoading, isError, onFilterChange} =
    useKanbanControl();

  const [selectedUsers, setSelectedUsers] = useState<KanbanUser[]>(
    currentUserData ? [currentUserToKanbanUser(currentUserData)] : []
  );

  useOnMount(() => {
    onFilterChange(businessCaseType, getSelectedUserIds(selectedUsers));
  });

  const onUserSelect = (filterdUsers: KanbanUser[]) => {
    pushGtmEvent({
      event: 'button_click',
      event_id: 'opportunities_kanban_user_control_change',
      value: {
        selectedUsers: filterdUsers.map((user) => user.email),
        numberOfSelectedUsers: filterdUsers.length,
      },
    });
    setSelectedUsers(filterdUsers);
    onFilterChange(businessCaseType, getSelectedUserIds(filterdUsers));
  };

  return (
    <>
      <Helmet title={i18n.t('page.opportunitiesKanban.title')} />
      <Main isFullHeight padding={0}>
        <Box
          position="sticky"
          backgroundColor="palettes.neutral.10.100"
          top={0}
          left={0}
          right={0}
          paddingHorizontal={[2, 4]}
        >
          <HStack width="100%" minHeight={[12, 16]} align="center" justify="space-between">
            <Segment<BusinessCaseType>
              value={businessCaseType}
              options={[
                {
                  label: i18n.t('page.opportunitiesKanban.selling'),
                  value: 'SELLING',
                },
                {
                  label: i18n.t('page.opportunitiesKanban.buying'),
                  value: 'BUYING',
                },
              ]}
              onChange={(value) => {
                onFilterChange(value, getSelectedUserIds(selectedUsers));
              }}
            />
            <DataStatus isLoading={isUsersLoading} isError={isUsersErrored} spinnerSize="small">
              {isNotNil(currentUserData) && isNotNil(users) ? (
                <KanbanUserControl
                  currentUserId={currentUserData.id}
                  users={users}
                  selectedUsers={selectedUsers}
                  onUserSelect={onUserSelect}
                />
              ) : null}
            </DataStatus>
          </HStack>
        </Box>
        <DataStatus
          isLoading={isLoading}
          isError={isError}
          isEmpty={!businessCases?.rows?.length && !interests?.rows?.length}
          emptyMessage={i18n.t('page.vehicle.notifications.noBusinessCases')}
          minHeight={100}
        >
          <Box width="100%" height="100%" overflow="auto" paddingBottom={[2, 4]}>
            <HStack spacing={2} height="100%" width="100%">
              {COLUMNS.map((column, columnIndex) => {
                const cards = getCardsForColumn(columnIndex, businessCases, interests);
                return (
                  <Box
                    key={`business-case-kanban-${column}`}
                    width="100%"
                    height="100%"
                    paddingLeft={columnIndex === 0 ? [2, 4] : 0}
                    paddingRight={isLastIndexInArray(COLUMNS, columnIndex) ? [2, 4] : 0}
                  >
                    <Box
                      key={`business-case-kanban-${column}`}
                      borderRadius="small"
                      minWidth={[65, 55]}
                      width="100%"
                      height="100%"
                      backgroundColor="palettes.neutral.30.100"
                      padding={2}
                    >
                      <VStack width="100%" spacing={3}>
                        <HStack spacing={1} align="center">
                          <Text size="small" alternative color="primary">
                            {i18n.t(column)}
                          </Text>
                          <Text size="small" color="tertiary">
                            {DOT_CHARACTER}
                          </Text>
                          <Text size="small" color="tertiary">
                            {cards.length || 0}
                          </Text>
                        </HStack>
                        {cards.map((card) => (
                          <Link
                            key={card.id}
                            to={composePath(
                              match([card.origin, businessCaseType])
                                .with(['business-case', 'BUYING'], () => businessCaseRoutes.buying)
                                .with(
                                  ['business-case', 'SELLING'],
                                  () => businessCaseRoutes.selling
                                )
                                .otherwise(() => interestRoutes.detail),
                              {
                                params: {id: card.id},
                                queryParams: {
                                  source: 'opportunities-kanban',
                                },
                              }
                            )}
                            onClick={() => {
                              pushGtmEvent({
                                event: 'button_click',
                                event_id: 'opportunities_kanban_card_click',
                                value: {
                                  card_id: card.id,
                                  card_origin: card.origin,
                                  card_business_case_type: businessCaseType,
                                },
                              });
                            }}
                          >
                            <KanbanCard
                              card={card}
                              backgroundColor={
                                selectedUsers.length > 1
                                  ? cardColors[
                                      selectedUsers.findIndex((user) => user.id === card.ownerId)
                                    ]
                                  : null
                              }
                              colorStripe={match<number, ColorSchemeType>(columnIndex)
                                .with(0, always('neutral'))
                                .with(1, always('yellow'))
                                .with(2, always('orange'))
                                .with(3, always('teal'))
                                .with(4, always('blue'))
                                .with(5, always('purple'))
                                .with(6, always('magenta'))
                                .with(7, always('green'))
                                .otherwise(always('black'))}
                            />
                          </Link>
                        ))}
                      </VStack>
                    </Box>
                  </Box>
                );
              })}
            </HStack>
          </Box>
        </DataStatus>
      </Main>
    </>
  );
}

const COLUMNS = [
  'page.opportunitiesKanban.newInterest',
  'page.opportunitiesKanban.activeInterest',
  'page.opportunitiesKanban.offer',
  'page.opportunitiesKanban.contract',
  'page.opportunitiesKanban.paymentRequest',
  'page.opportunitiesKanban.paymentReceived',
  'page.opportunitiesKanban.invoice',
  'page.opportunitiesKanban.paid',
];

const currentUserToKanbanUser = (data: GetCurrentUserInfoApiResponse): KanbanUser => ({
  ...omit(['settings', 'firstName', 'lastName'], data),
  fullName:
    getNaturalPersonFullName({
      firstName: data.firstName,
      lastName: data.lastName,
    }) || EMPTY_PLACEHOLDER,
  isHidden: false,
});
