import {memo, useCallback, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';

import {
  FeaturesPicker,
  permanentVehicleType,
  selectFeatureCategories,
  selectVehicleFeatures,
  VehicleCatalogueEnumOption,
} from '@dms/teas';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useFilters} from '../../../FiltersContext/FiltersContext';

export function FilterFeatures(props: TestIdProps) {
  const {filters, onUpdateFilters} = useFilters();
  const selectedFeatures = useMemo(() => filters.features || [], [filters.features]);

  const featureCategories = useSelector(
    selectFeatureCategories(permanentVehicleType),
    shallowEqual
  );
  const features = useSelector(selectVehicleFeatures(permanentVehicleType), shallowEqual);

  const handleFeatureSelect = useCallback(
    (selected: string[]) => {
      onUpdateFilters(['features'], selected);
    },
    [onUpdateFilters]
  );

  return (
    <MemoizedFeaturesPicker
      featureCategories={featureCategories}
      features={features}
      selectedFeatures={selectedFeatures}
      onFeatureSelect={handleFeatureSelect}
      data-testid={props['data-testid']}
    />
  );
}

interface MemoizedFeaturesPickerProps extends TestIdProps {
  featureCategories: VehicleCatalogueEnumOption[];
  features: VehicleCatalogueEnumOption[];
  selectedFeatures: string[] | Nullish;
  onFeatureSelect: (selected: string[]) => void;
}

const MemoizedFeaturesPicker = memo((props: MemoizedFeaturesPickerProps) => (
  <FeaturesPicker
    categories={props.featureCategories}
    features={props.features}
    selected={props.selectedFeatures}
    onFeatureSelect={props.onFeatureSelect}
    allowSubItemMultiselect
    data-testid={suffixTestId('features', props)}
  />
));
