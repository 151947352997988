import {Button, showNotification} from 'platform/components';
import {Show, Space} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useLocation, useMatch, useNavigate} from 'react-router-dom';

import {always} from 'ramda';
import {isNotNilOrEmpty, isNotString} from 'ramda-adjunct';

import {sourcingRoutes} from '@dms/routes';
import {queryParams, useNavigationBreadCrumb} from '@dms/shared';
import {Navigation} from '@dms/teas';

import {composePath, RequiredTestIdProps, suffixTestId} from 'shared';

import {
  convertToSearchParams,
  getFormattedSourceSearchParams,
  getSourceSearchParams,
  RowControlWrapper,
} from 'features/datagrid';

function SourcingNavigation(props: RequiredTestIdProps) {
  return <Navigation data-testid={suffixTestId('navigation', props)} />;
}

function GeneralNavigation(props: RequiredTestIdProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const [breadcrumbProps, isBreadcrumbVisible] = useNavigationBreadCrumb();
  const isRowControlDetailVisible = Boolean(breadcrumbProps?.rowControlDetail);
  const rowControlDetailMatch = useMatch(breadcrumbProps?.rowControlDetail ?? '');

  const onBreadCrumbClick = () => {
    if (!breadcrumbProps) {
      showNotification.error();
      return;
    }

    if (isNotNilOrEmpty(getSourceSearchParams())) {
      const sourceGridCode = getFormattedSourceSearchParams().gridCode;

      const vehicleTab = match(sourceGridCode)
        .with('vehicles-in-advertisement', always('/in-advertisement'))
        .with('vehicle_in_warehouse_history', always('/warehouses'))
        .otherwise(always(''));

      const newSearchParams = convertToSearchParams(
        {
          [getFormattedSourceSearchParams().gridCode]: getFormattedSourceSearchParams(),
        },
        true
      );

      navigate(
        composePath(breadcrumbProps?.navigateTo + vehicleTab, {
          queryParams: newSearchParams,
          isSearchParamsPreserved: false,
        })
      );

      return;
    }

    navigate(breadcrumbProps?.navigateTo);
  };

  const handleChange = (newEntity: string, sourceSearchParams: string) => {
    if (isNotString(breadcrumbProps?.rowControlDetail)) {
      throw Error('You need to specify rowControlDetail');
    }

    const URLSourceSearchParams = new URLSearchParams(sourceSearchParams);
    const locationSource = new URLSearchParams(location.search).get(queryParams.REDIRECT_SOURCE);
    if (locationSource) {
      URLSourceSearchParams.append(queryParams.REDIRECT_SOURCE, locationSource);
    }

    if (breadcrumbProps?.rowControlDetail) {
      const composedPath = composePath(
        breadcrumbProps.rowControlDetail as `${string}/:id${string}`,
        {
          params: {
            ...rowControlDetailMatch?.params,
            id: newEntity,
          },
          queryParams: URLSourceSearchParams.toString(),
          isSearchParamsPreserved: false,
        }
      );

      navigate(composedPath);
    }
  };

  return (
    <>
      <Show when={isBreadcrumbVisible}>
        <Button
          leftIcon="navigation/arrow_back"
          title={breadcrumbProps?.title}
          variant="link"
          onClick={onBreadCrumbClick}
          data-testid={suffixTestId('back-button', props)}
        />
      </Show>
      <Space horizontal={5} />
      <Show when={isRowControlDetailVisible}>
        <RowControlWrapper onChange={handleChange} />
      </Show>
    </>
  );
}

export function PageNavigation(props: RequiredTestIdProps) {
  const location = useLocation();

  if (location.pathname.includes(sourcingRoutes.home)) {
    return <SourcingNavigation data-testid={props['data-testid']} />;
  }

  return <GeneralNavigation data-testid={props['data-testid']} />;
}
