import {captureException, ErrorBoundary} from '@sentry/react';
import {useInitFlagsmith} from 'feature-flags';
import {showNotification} from 'platform/components';
import {Hide} from 'platform/foundation';

import {Suspense, lazy, useEffect} from 'react';
import {Outlet} from 'react-router-dom';

import {useListPermissionExpressionQuery} from '@dms/api/accessControlList';
import {getWorkspaceFromUri} from '@dms/api/core';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';
import {browserStorageKey} from '@dms/config';
import {environment} from '@dms/environment';
import i18n from '@dms/i18n';
import {CommonSkeleton} from '@dms/shared';

import {isTestEnvironment} from '../../utils/isTestEnvironment';
import {ApolloProvider} from '../ApolloProvider/ApolloProvider';
import {NotificationSubscriptionProvider} from '../NotificationSubscriptionProvider/NotificationSubscriptionProvider';
import {ApplicationUpdateBoundary} from './components/ApplicationUpdateBoundary';
import {PrefetchBoundary} from './components/PrefetchBoundary';
import {RotateOverlay} from './components/RotateOverlay';
import {SentryErrorBoundary} from './components/SentryErrorBoundary';
import {useOrientation} from './hooks/useOrientation';
import {sentryInit} from './sentryInit';

const Datadog = lazy(() => import('./components/Datadog'));

export function Initialize() {
  const isLandscape = useOrientation();

  const {workspace} = getWorkspaceFromUri();

  const {data: userInfo} = useGetCurrentUserInfoQuery();
  const {data: userPermissions} = useListPermissionExpressionQuery();

  const language = userInfo?.settings?.language;

  const isFlagSmithInitialized = useInitFlagsmith({
    userEmail: userInfo?.email,
    accessTokenStorageKey: browserStorageKey.ACCESS_TOKEN,
    environmentId: environment.FLAGSMITH_ENV,
    flagsmithUrl: environment.FLAGSMITH_URL,
  });

  useEffect(() => {
    const dataLayer = {
      environment: environment.ENV_TYPE,
      workspace: workspace ?? '',
      userEmail: '',
    };
    if (userInfo && userInfo.id && userInfo.email && !isTestEnvironment) {
      dataLayer.userEmail = userInfo.email;

      sentryInit(userInfo);

      if (!window.dataLayer) {
        captureException(
          'Google Tag Manager (GTM) is not initialized: window.dataLayer is not defined'
        );
        return;
      }
      window.dataLayer.push(dataLayer);
    }

    if (!language || language === localStorage[browserStorageKey.LAST_KNOWN_LANGUAGE]) {
      return;
    }
    i18n
      .changeLanguage(language)
      .then(() => localStorage.setItem(browserStorageKey.LAST_KNOWN_LANGUAGE, language))
      .catch(() => showNotification.error('fatal error - failed to set the application language'));
  }, [workspace, userInfo, language]);

  if (!userInfo || !language || !userPermissions || !isFlagSmithInitialized) {
    return <CommonSkeleton />;
  }

  return (
    <SentryErrorBoundary>
      <ErrorBoundary>
        <Suspense fallback={null}>
          <Datadog />
        </Suspense>
      </ErrorBoundary>
      <ApolloProvider>
        <PrefetchBoundary>
          <ApplicationUpdateBoundary>
            <NotificationSubscriptionProvider>
              <Outlet />
            </NotificationSubscriptionProvider>
            <Hide when={isLandscape} onDesktop onNotebook>
              <RotateOverlay />
            </Hide>
          </ApplicationUpdateBoundary>
        </PrefetchBoundary>
      </ApolloProvider>
    </SentryErrorBoundary>
  );
}
