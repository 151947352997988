import {useCallback, useMemo} from 'react';

import {isNil, isNotNil, uniq} from 'ramda';

import {useGetAllMakesModelsQuery} from '@dms/api/vehicleCatalogue';

import {useFilters} from '../../FiltersContext/FiltersContext';
import {getOptionType} from '../utils/getOptionType';
import {isModelLastMakeSelected} from '../utils/isModelLastMakeSelected';

export function useMakeModelFilter() {
  const {filters, onUpdateFilters} = useFilters();
  const {data, isLoading} = useGetAllMakesModelsQuery({vehicleType: 'VEHICLETYPE_PASSENGER_CAR'});

  /**
   * Get a model by its key
   * @param modelKey
   */
  const getModel = useCallback(
    (modelKey?: string) => (isNotNil(modelKey) ? data?.models[modelKey] : null),
    [data?.models]
  );

  /**
   * Get a model family by its key
   * @param modelFamilyKey
   */
  const getModelFamily = useCallback(
    (modelFamilyKey?: string) =>
      isNotNil(modelFamilyKey) ? data?.modelFamilies[modelFamilyKey] : null,
    [data?.modelFamilies]
  );

  /**
   * Get a make by its key
   * @param makeKey
   */
  const getMake = useCallback(
    (makeKey?: string) => (isNotNil(makeKey) ? data?.makes[makeKey] : null),
    [data?.makes]
  );

  /**
   * All selected makes (no makes excluded)
   */
  const allSelectedMakes = useMemo(
    () =>
      Object.entries(filters.make ?? {})
        .filter(([_key, value]) => value)
        .map(([key]) => key),
    [filters.make]
  );

  /**
   * All selected models (no models excluded)
   */
  const allSelectedModels = useMemo(
    () =>
      Object.entries(filters.model ?? {})
        .filter(([_key, value]) => value)
        .map(([key]) => key),
    [filters.model]
  );

  /**
   * Selected model families (those which have all their models selected)
   */
  const selectedModelFamilies = useMemo(
    () =>
      Object.entries(data?.modelFamilies ?? {})
        .filter(([_key, family]) =>
          family.models.every((model) => allSelectedModels.includes(model))
        )
        .map(([key]) => key),
    [allSelectedModels, data?.modelFamilies]
  );

  /**
   * Makes with some models selected
   */
  const makesWithSelectedModels = useMemo(
    () => uniq(allSelectedModels.map((model) => getModel(model)?.make).filter(isNotNil)),
    [allSelectedModels, getModel]
  );

  /**
   * Selected makes minus those with some models selected
   */
  const selectedMakes = useMemo(
    () => allSelectedMakes.filter((make) => !makesWithSelectedModels.includes(make)),
    [allSelectedMakes, makesWithSelectedModels]
  );

  /**
   * Models from selected model families
   */
  const modelsFromSelectedModelFamilies = allSelectedModels.filter(
    (model) => {
      const family = getModel(model)?.modelFamily;
      return isNotNil(family) && selectedModelFamilies.includes(family);
    },
    [allSelectedModels, getModel, selectedModelFamilies]
  );

  /**
   * Selected models excluding those from selected families
   */
  const selectedModels = useMemo(
    () => allSelectedModels.filter((model) => !modelsFromSelectedModelFamilies.includes(model)),
    [allSelectedModels, modelsFromSelectedModelFamilies]
  );

  /**
   * Selects a make
   * @param makeKey
   */
  const selectMake = useCallback(
    (makeKey: string) => {
      onUpdateFilters(['make', makeKey], true);
    },
    [onUpdateFilters]
  );

  /**
   * Unselects a make
   * @param makeKey
   */
  const unselectMake = useCallback(
    (makeKey: string) => {
      onUpdateFilters(['make', makeKey], false);
    },
    [onUpdateFilters]
  );

  /**
   * Selects a model without touching makes
   * @param modelKey
   */
  const simpleSelectModel = useCallback(
    (modelKey: string) => {
      onUpdateFilters(['model', modelKey], true);
    },
    [onUpdateFilters]
  );

  /**
   * Selects a model and also the make of the model
   * @param modelKey
   */
  const selectModel = useCallback(
    (modelKey: string) => {
      simpleSelectModel(modelKey);
      const model = getModel(modelKey);
      isNotNil(model) && onUpdateFilters(['make', model.make], true);
    },
    [getModel, onUpdateFilters, simpleSelectModel]
  );

  /**
   * Unselects a model without touching makes
   * @param modelKey
   */
  const simpleUnselectModel = useCallback(
    (modelKey: string) => {
      onUpdateFilters(['model', modelKey], false);
    },
    [onUpdateFilters]
  );

  /**
   * Unselects a model, if it is the last model of the make selected, unselects the make
   * @param modelKey
   */
  const unselectModel = useCallback(
    (modelKey: string) => {
      simpleUnselectModel(modelKey);
      const make = getMake(getModel(modelKey)?.make);
      if (isNotNil(make) && isModelLastMakeSelected(modelKey, selectedModels, make)) {
        onUpdateFilters(['make', make.name], false);
      }
    },
    [getMake, getModel, onUpdateFilters, selectedModels, simpleUnselectModel]
  );

  /**
   * Selects a model family
   * @param modelFamilyKey
   */
  const selectModelFamily = useCallback(
    (modelFamilyKey: string) => {
      const modelFamily = getModelFamily(modelFamilyKey);
      isNotNil(modelFamily) && onUpdateFilters(['make', modelFamily.make], true);
      modelFamily?.models.forEach((model) => onUpdateFilters(['model', model], true));
    },
    [getModelFamily, onUpdateFilters]
  );

  /**
   * Unselects a model family
   * @param modelFamilyKey
   */
  const unselectModelFamily = useCallback(
    (modelFamilyKey: string) => {
      const modelFamily = getModelFamily(modelFamilyKey);
      const make = getMake(modelFamily?.make);
      modelFamily?.models.forEach((modelKey) => {
        onUpdateFilters(['model', modelKey], false);
        if (isModelLastMakeSelected(modelKey, selectedModels, make)) {
          onUpdateFilters(['make', modelFamily.make], false);
        }
      });
    },
    [getMake, getModelFamily, onUpdateFilters, selectedModels]
  );

  /**
   * Selects a make, model or model family
   *   (type detected based on prefix of the code of make/model/model family)
   * @param value
   */
  const selectMakeModel = useCallback(
    (value: string | null) => {
      if (isNil(value)) {
        return;
      }

      const optionType = getOptionType(value);
      if (optionType === 'make') {
        selectMake(value);
      }
      if (optionType === 'model') {
        selectModel(value);
      }
      if (optionType === 'modelFamily') {
        selectModelFamily(value);
      }
    },
    [selectMake, selectModel, selectModelFamily]
  );

  /**
   * Set a new set (values) of models, but does not touch the makes
   *  - Selects those in values
   *  - Unselects those in the current filter but missing in values
   * @param values
   */
  const simpleUpdateModels = useCallback(
    (values: string[] | null) => {
      values?.forEach((value) => {
        simpleSelectModel(value);
      });

      allSelectedModels.forEach((modelKey) => {
        if (!values?.includes(modelKey)) {
          simpleUnselectModel(modelKey);
        }
      });
    },
    [allSelectedModels, simpleSelectModel, simpleUnselectModel]
  );

  /**
   * Set a new set (values) of makes, models and model families
   *  - Selects those in values
   *  - Unselects those in the current filter but missing in values
   * @param values
   */
  const updateMakesModels = useCallback(
    (values: string[] | null) => {
      values?.forEach((value) => {
        selectMakeModel(value);
      });

      selectedMakes.forEach((makeKey) => {
        if (!values?.includes(makeKey)) {
          unselectMake(makeKey);
        }
      });
      selectedModels.forEach((modelKey) => {
        if (!values?.includes(modelKey)) {
          unselectModel(modelKey);
        }
      });
      selectedModelFamilies.forEach((modelFamilyKey) => {
        if (!values?.includes(modelFamilyKey)) {
          unselectModelFamily(modelFamilyKey);
        }
      });
    },
    [
      selectedMakes,
      selectedModels,
      selectedModelFamilies,
      selectMakeModel,
      unselectMake,
      unselectModel,
      unselectModelFamily,
    ]
  );

  return {
    models: data?.models,
    makes: data?.makes,
    modelFamilies: data?.modelFamilies,
    allSelectedModels,
    allSelectedMakes,
    selectedModels,
    selectedMakes,
    selectedModelFamilies,
    isLoading,
    getMake,
    getModel,
    getModelFamily,
    selectMake,
    unselectMake,
    selectModel,
    unselectModel,
    selectModelFamily,
    unselectModelFamily,
    selectMakeModel,
    simpleUpdateModels,
    updateMakesModels,
  };
}
